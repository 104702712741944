import CategoryProductList from 'Component/CategoryProductList';
import ContentWrapper from 'Component/ContentWrapper';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    SearchPageComponent as SourceSearchPage,
} from 'SourceRoute/SearchPage/SearchPage.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './SearchPage.override.style';

/** @namespace Pwa/Route/SearchPage/Component */
export class SearchPageComponent extends SourceSearchPage {
    renderCategoryTitle() {
        const { search } = this.props;

        return (
            <div block="CategoryTitle">
                <div block="CategoryTitle" elem="Heading">
                    <h1>
                        <TextPlaceholder content={ `${__('Search results: ') }"${ search }"` } />
                    </h1>
                </div>
            </div>
        );
    }

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div block="CategoryPage" elem="ProductListWrapper">
                { this.renderItemsCount(true) }

                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ false } // FIX: Loading bug
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType }
                />
            </div>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderCategoryTitle() }
                { this.renderCategoryProductList() }
            </>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();

        return (
            <main block="CategoryPage SearchPage">
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Search page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SearchPageComponent;
